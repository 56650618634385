
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
// import Multiselect from "@vueform/multiselect";
import ReportService from "@/core/services/car/ReportService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {yearList, roles} from "@/store/stateless/store";
import RecordSelect from "../components/recordSelect.vue";
import moment from "moment";
import { getMonthDate } from "@/store/stateless/store";
import { leadStatusMixin } from "@/core/plugins/vcMixins";


interface IPayloadLBS {
  year: any;
  // month: number;
  start_date: any;
  end_date: any;
  lead_source: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
  lead_status_id: string;
  per_page: number | string;
  page: number;
}

export default defineComponent({
  // component name
  name: "report-lead-by-source",
  components: {
    RecordSelect
  },
  data() {
    // constants
    return {};
  },
  setup() {
    const store = useStore();
    const value2 = ref<Array<string>>([]);

    let bodyPayloads = ref<IPayloadLBS>({
      year: new Date().getFullYear(),
      // month: new Date().getMonth() + 1,.
      start_date: moment().startOf("month").format("YYYY-MM-DD"),
      end_date: moment().endOf("month").format("YYYY-MM-DD"),
      lead_source: "all",
      utm_source: "all",
      utm_medium: "all",
      utm_campaign: "all",
      utm_content: "all",
      per_page: 50,
      page: 1,
      lead_status_id: "all",
    });

    let exportUrl = ref<string>("");

    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });

    let yearsList = computed(() => {
      return store.getters.getLBSYears;
    });

    let monthList = computed(() => {
      return store.getters.getLBSMonths;
    });

    let leadSources = computed(() => {
      return store.getters.getLBSSources;
    });

    let utmSources = computed(() => {
      return store.getters.getLBSUTMSources;
    });

    let utmMediums = computed(() => {
      return store.getters.getLBSUTMMedium;
    });

    let utmCampaigns = computed(() => {
      return store.getters.getLBSUTMCampaign;
    });

    let utmContents = computed(() => {
      return store.getters.getLBSUTMContent;
    });

    let carLeadStatuses = computed(() => {
      return store.getters.getReportLeadStatuses;
    });

    let salesMade = computed(() => {
      return store.getters.getReportSalesMade;
    });

    let filterYearList = ref({
      value: new Date().getFullYear(),
      options: yearsList,
      placeholder: "Select Year",
      searchable: true,
      createTag: true
    });

    let filterMonthsList = ref({
      value: new Date().getMonth() + 1,
      options: monthList,
      placeholder: "Select Month",
      searchable: true,
      createTag: true
    });

    let filterSources = ref({
      value: "all",
      options: leadSources,
      placeholder: "Select Lead Source",
      searchable: true,
      createTag: true
    });

    let filterUtmSources = ref({
      value: "all",
      options: utmSources,
      placeholder: "Select UTM Source",
      searchable: true,
      createTag: true
    });

    let filterUtmMediums = ref({
      value: "all",
      options: utmMediums,
      placeholder: "Select UTM Medium",
      searchable: true,
      createTag: true
    });

    let filterUtmCampaigns = ref({
      value: "all",
      options: utmCampaigns,
      placeholder: "Select UTM Campaign",
      searchable: true,
      createTag: true
    });

    let filterUtmContents = ref({
      value: "all",
      options: utmContents,
      placeholder: "Select UTM Content",
      searchable: true,
      createTag: true
    });

    const resetAllFilters = () => {
      Promise.all([ReportService.fetchingLeadBySourceFilters()]).then(() => {
        filterYearList.value = {
          value: new Date().getFullYear(),
          options: yearsList,
          placeholder: "Select Year",
          searchable: true,
          createTag: true
        };
        filterMonthsList.value = {
          value: new Date().getMonth() + 1,
          options: monthList,
          placeholder: "Select Month",
          searchable: true,
          createTag: true
        };
        filterSources.value = {
          value: "all",
          options: leadSources,
          placeholder: "Select Lead Source",
          searchable: true,
          createTag: true
        };
        filterUtmSources.value = {
          value: "all",
          options: utmSources,
          placeholder: "Select BTM Source",
          searchable: true,
          createTag: true
        };
        filterUtmMediums.value = {
          value: "all",
          options: utmMediums,
          placeholder: "Select UTM Medium",
          searchable: true,
          createTag: true
        };
        filterUtmCampaigns.value = {
          value: "all",
          options: utmContents,
          placeholder: "Select UTM Campaign",
          searchable: true,
          createTag: true
        };
        filterUtmContents.value = {
          value: "all",
          options: utmContents,
          placeholder: "Select UTM Content",
          searchable: true,
          createTag: true
        };
      });
    };

    const defaultBodyPayloads = computed(() => {
      return store.getters.getCommonPayLoadLBS;
    });
    const displayResponseData = computed(() => {
      return store.getters.getLeadBySourceReport;
    });

    const myPagination = computed(() => {
      return store.getters.getExportPagination;
    });

    const getPaginationUrl = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyPayloads.value.page = currentPage.value;
      ReportService.callingLeadBySourceReport(bodyPayloads.value);
    };

    const onPerPageChange = (event) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyPayloads.value.per_page = event.target.value;
      ReportService.callingLeadBySourceReport(bodyPayloads.value);
    };

    const exportReport = () => {
      Promise.all([
        ReportService.exportingLeadBySourceReport(bodyPayloads.value)
      ]).then(() => {
        let exportResult = computed(() => {
          return store.getters.getExport;
        });
        exportUrl.value = exportResult.value;
      });
    };

    const onYearChange = () => {
      // bodyPayloads.value.year = event;
      bodyPayloads.value.page = 1;
      let firstDay = new Date(bodyPayloads.value.year, 1 - 1);
      let lastDay = new Date(bodyPayloads.value.year, 12, 0);
      value2.value = []
      setDateRange(firstDay, lastDay);
      ReportService.callingLeadBySourceReport(bodyPayloads.value);
    };

    const setDateRange = (firstDay, lastDay) => {
      bodyPayloads.value.start_date = moment(firstDay).format("YYYY-MM-DD");
      bodyPayloads.value.end_date = moment(lastDay).format("YYYY-MM-DD");
    };

    const onMonthChange = () => {
      // bodyPayloads.value.month = event;
      bodyPayloads.value.page = 1;
      ReportService.callingLeadBySourceReport(bodyPayloads.value);
    };

    const onLeadSourceChange = () => {
      // bodyPayloads.value.lead_source = event;
      ReportService.callingLeadBySourceReport(bodyPayloads.value);
    };

    const onUTMSourceChange = (event) => {
      // bodyPayloads.value.utm_source = event;
      ReportService.callingLeadBySourceReport(bodyPayloads.value);
    };

    const onUTMMediumChange = (event) => {
      // bodyPayloads.value.utm_medium = event;
      ReportService.callingLeadBySourceReport(bodyPayloads.value);
    };

    const onUTMCampaignChange = (event) => {
      // bodyPayloads.value.utm_campaign = event;
      ReportService.callingLeadBySourceReport(bodyPayloads.value);
    };

    const onUTMContentChange = (event) => {
      // bodyPayloads.value.utm_content = event;
      ReportService.callingLeadBySourceReport(bodyPayloads.value);
    };

    const resetYear = (event) => {
      bodyPayloads.value.year = event == null ? "" : event;
      ReportService.callingLeadBySourceReport(bodyPayloads.value);
    };

    // const resetMonth = (event) => {
    //   bodyPayloads.value.month = event == null ? "" : event;
    //   ReportService.callingLeadBySourceReport(bodyPayloads.value);
    // };

    const resetSources = (event) => {
      bodyPayloads.value.lead_source = event == null ? "" : event;
      ReportService.callingLeadBySourceReport(bodyPayloads.value);
    };

    const resetUtmSource = (event) => {
      bodyPayloads.value.utm_source = event == null ? "" : event;
      ReportService.callingLeadBySourceReport(bodyPayloads.value);
    };

    const resetUtmMedium = (event) => {
      bodyPayloads.value.utm_medium = event == null ? "" : event;
      ReportService.callingLeadBySourceReport(bodyPayloads.value);
    };

    const resetUtmCampaign = (event) => {
      bodyPayloads.value.utm_campaign = event == null ? "" : event;
      ReportService.callingLeadBySourceReport(bodyPayloads.value);
    };

    const resetUtmContent = (event) => {
      bodyPayloads.value.utm_content = event == null ? "" : event;
      ReportService.callingLeadBySourceReport(bodyPayloads.value);
    };

    const resetAndRefreshFilter = () => {
      bodyPayloads.value = {
        year: new Date().getFullYear(),
        // month: new Date().getMonth()+ 1,
        start_date: moment().startOf("month").format("YYYY-MM-DD"),
        end_date: moment().endOf("month").format("YYYY-MM-DD"),
        lead_source: "all",
        utm_source: "all",
        utm_medium: "all",
        utm_campaign: "all",
        utm_content: "all",
        lead_status_id: "all",
        per_page: 50,
        page: 1
      };
      ReportService.callingLeadBySourceReport(bodyPayloads.value);
      resetAllFilters();
      exportUrl.value = "";
    };

    onMounted(() => {
      if(!roles.reportLeadSource.includes(roles.userId())) window.location.href = '/#/404'
      
      value2.value = getMonthDate.currentMonth();
      setCurrentPageBreadcrumbs("Lead By Source", ["Reports"]);
      Promise.all([
        ReportService.callingLeadBySourceReport(bodyPayloads.value),
        ReportService.fetchingLeadBySourceFilters()
      ]).then((data) => {
          // console.log()
      });
      
    });

    const currentPage = ref(1);

    const user = computed(() => {
      return store.getters.currentUser;
    });
    const years = yearList.getYearList();
    
    const loading = computed(() => {
      return store.getters.getARLoading;
    });


    const searchByDate = (listPeriod) => {
      if(listPeriod) {
        var startTS = new Date(listPeriod[0]);
        var endTS = new Date(listPeriod[1]);
        bodyPayloads.value.year = ""
        bodyPayloads.value.start_date = moment(startTS.getFullYear() + "-" + (startTS.getMonth() + 1) + "-" + startTS.getDate()).format("YYYY-MM-DD");
        bodyPayloads.value.end_date = moment(endTS.getFullYear() + "-" + (endTS.getMonth() + 1) + "-" + endTS.getDate()).format("YYYY-MM-DD");
          endTS.getFullYear() +
          "-" +
          (endTS.getMonth() + 1) +
          "-" +
          endTS.getDate();
      } else {
        bodyPayloads.value.start_date = '';
        bodyPayloads.value.end_date = '';
      }
      bodyPayloads.value.page = 1;
      ReportService.callingLeadBySourceReport(bodyPayloads.value);
    };
    return {
      leadStatusMixin,
      searchByDate,
      carLeadStatuses,
      salesMade,
      loading,
      roles,
      value2,
      currentPage,
      bodyPayloads,
      defaultBodyPayloads,
      displayResponseData,
      myPagination,
      onPerPageChange,
      getPaginationUrl,
      resetAndRefreshFilter,
      exportUrl,
      exportReport,
      disableSaveButton,
      filterYearList,
      filterMonthsList,
      filterSources,
      filterUtmSources,
      filterUtmMediums,
      filterUtmCampaigns,
      filterUtmContents,
      onYearChange,
      onMonthChange,
      onLeadSourceChange,
      onUTMSourceChange,
      onUTMMediumChange,
      onUTMCampaignChange,
      onUTMContentChange,
      resetYear,
      // resetMonth,
      resetSources,
      resetUtmSource,
      resetUtmMedium,
      resetUtmCampaign,
      resetUtmContent,
      user,
      years,
      monthList,
      leadSources,
      utmSources,
      utmMediums,
      utmCampaigns,
      utmContents
    }
  },
  methods: {
    indexMethod(index) {
      return (this.myPagination.current_page * this.myPagination.per_page) - this.myPagination.per_page + index + 1;
    }
  }

});
